import { unicodeSplit } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  guess: string
  className: string
  letterToReveal: string
  solution: string
}

export const CurrentRow = ({ guess, className, letterToReveal, solution}: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
          <Cell key={i} value={letter} />
      ))}
      {emptyCells.map((_, i) => {
        if (letterToReveal.indexOf(solution[i + splitGuess.length]) === -1) {
          return <Cell key={i} isCurrent={i === 0} /> // The object?
       } else {
          return <Cell
          key={i}
          value={solution[i + splitGuess.length]}
          status={'hint'}
          isRevealing={true}
          isCurrent={i === 0}
        /> // A different object?
       }
      })}
    </div>
  )
}
