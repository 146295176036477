import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

type Props = {
  solution: string[]
  guesses: string[]
  currentGuess: string,
  letterToReveal: string,
  isRevealing?: boolean
  currentRowClassName: string
}

export const Grid = ({
  solution,
  guesses,
  currentGuess,
  letterToReveal,
  isRevealing,
  currentRowClassName,
}: Props) => {
  const empties =
    guesses.length < solution.length 
      ? Array.from(Array(solution.length - 1 - guesses.length))
      : []
  
  
  return (
    <>
      {guesses.map((guess, i) => (
        <CompletedRow
          key={i}
          solution={solution[i]}
          guess={guess}
          isRevealing={isRevealing && guesses.length - 1 === i}
        />
      ))}
      {guesses.length < solution.length  && (
        <CurrentRow guess={currentGuess} className={currentRowClassName} letterToReveal={letterToReveal} solution={solution[guesses.length]}/>
      )}
      {empties.map((_, i) => (
        <EmptyRow key={i} letterToReveal={letterToReveal} solution={solution[i + guesses.length + 1]}/>
      ))}
    </>
  )
}
