import { solution } from '../../lib/words'
import { Cell } from './Cell'

type Props = {
  letterToReveal: string
  solution: string
}

export const EmptyRow = ({letterToReveal, solution}: Props) => {
  const emptyCells = Array.from(Array(solution.length))
 
  return (
    <div className="mb-1 flex justify-center">
      {emptyCells.map((_, i) => {
        if (letterToReveal.indexOf(solution[i]) === -1) {
          return <Cell key={i} /> // The object?
       } else {
          return <Cell
          key={i}
          value={solution[i]}
          status={'hint'}
          isRevealing={true}
        /> // A different object?
       }
      })}
    </div>
  )
  // return (
  //   <div className="mb-1 flex justify-center">
  //     {emptyCells.map((_, i) => {
        
  //       return <Cell key={i} /> // The object?
       
  //     })}
  //   </div>
  // )
}
