import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word square! A word square is a 5x5 set of words that are the same horizontally and vertically. 
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="G" isCompleted={true} status={"correct"} isRevealing={true} position={1}/>
        <Cell value="R" isCompleted={true} status={"correct"} isRevealing={true} position={2}/>
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={3}/>
        <Cell value="A" isCompleted={true} status={"correct"} isRevealing={true} position={4}/>
        <Cell value="T" isCompleted={true} status={"correct"} isRevealing={true} position={5}/>
      </div>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="R" isCompleted={true} status={"correct"}isRevealing={true} position={6}/>
        <Cell value="I" isCompleted={true} status={"correct"}isRevealing={true} position={7} />
        <Cell value="D" isCompleted={true} status={"correct"} isRevealing={true} position={8}/>
        <Cell value="G" isCompleted={true} status={"correct"} isRevealing={true} position={9}/>
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={10}/>
      </div>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={11} />
        <Cell value="D" isCompleted={true} status={"correct"} isRevealing={true} position={12}/>
        <Cell value="G" isCompleted={true} status={"correct"} isRevealing={true} position={13}/>
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={14}/>
        <Cell value="S" isCompleted={true} status={"correct"} isRevealing={true} position={15}/>
      </div>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="A" isCompleted={true} status={"correct"} isRevealing={true} position={16}/>
        <Cell value="G" isCompleted={true} status={"correct"} isRevealing={true} position={17}/>
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={18}/>
        <Cell value="N" isCompleted={true} status={"correct"} isRevealing={true} position={19}/>
        <Cell value="T" isCompleted={true} status={"correct"} isRevealing={true} position={20}/>
      </div>
      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="T" isCompleted={true} status={"correct"} isRevealing={true} position={21}/>
        <Cell value="E" isCompleted={true} status={"correct"} isRevealing={true} position={22}/>
        <Cell value="S" isCompleted={true} status={"correct"} isRevealing={true} position={23}/>
        <Cell value="T" isCompleted={true} status={"correct"} isRevealing={true} position={24}/>
        <Cell value="S" isCompleted={true} status={"correct"} isRevealing={true} position={25}/>
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        Above is an example of a word square.
      </p>

      <hr></hr>

      <p className="text-sm text-gray-500 dark:text-gray-300">
        A Daily Word Puzzle Game. Play now for free!
      </p>

      
    </BaseModal>
  )
}
